.faq-overview {

	.faq-category {
		.faq-title {
			pointer-events: none;

			.faq-icon {
				display: none;
			}
		}

		.faq-items {
			display: block !important;
			margin: 15px 0 30px 0;

			.faq-item {
				margin-bottom: 5px;
				padding: 7px 0;
				border-bottom: 1px solid $primary;

				.faq-question {
					display: flex;
					align-items: center;

					h3 {
						margin-bottom: 0;
						font-weight: 600;
						font-family: $font-family-primary;
						transition: color 0.5s;
					}

					&:hover {
						cursor: pointer;

						h3 {
							color: $secondary;
						}
					}

					.faq-icon {
						margin-left: auto;
						line-height: 1;

						i {
							&::before {
								content: "\f107";
								color: inherit;
								font-size: 20px;
								font-weight: 900;
							}
						}
					}
				}

				&.active {
					.faq-question {
						.faq-icon {
							i {
								&::before {
									content: "\f106";
								}
							}
						}
					}
				}

				.faq-answer {
					display: none;
					margin-top: 15px;

					p {
						&:last-of-type {
							margin-bottom: 0;
						}
					}

				}
			}
		}
	}

	&.js-single-category {
		.faq-items {
			display: block;
		}
	}
}
