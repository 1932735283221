// bg-*
// =========================================================================
section {

	&.bg-light,
	&.bg-dark {
		margin: 0;
		padding: 6vh 0;
		background: url('/images/wood-pattern-bg.svg');
		background-size: 431px 435px;
	}

	.column {
		z-index: 2; // above 'M'
	}
}

// intro-section
// =========================================================================
.intro-section {
	padding-top: calc(6vh + 30px);
	padding-bottom: calc(6vh + 45px);

	.column {
		z-index: 3; // fix for arrow
	}

	.title {
		text-align: center;

		h2 {
			position: relative;
			display: inline-block;
			@include font-size($h1-font-size);

			&::after {
				content: "";
				z-index: 100;
				position: absolute;
				top: 100%;
				display: block;
				width: 87px;
				height: 129px;
			}

			&.arrow-right {
				&::after {
					content: "";
					left: 20%;
					background: url('/images/arrow-right-long.svg');
				}
			}

			&.arrow-left {
				&::after {
					content: "";
					right: 20%;
					background: url('/images/arrow-left-long.svg');
				}
			}
		}
	}
}

// featured-section
// =========================================================================
.featured-section {
	padding: 6vh 0;
	background: url('/images/wood-pattern-bg.svg') $blue-light;
	background-size: 431px 435px;

	.container-two-columns {
		.container-holder {
			.column {
				&.one {
					padding-right: 15px !important;
				}

				&.two {
					padding-left: 15px !important;
				}

				&.wide {
					@include media-breakpoint-up(md) {
						@include make-col(8);
					}
				}

				&.narrow {
					@include media-breakpoint-up(md) {
						@include make-col(4);
					}

					@include media-breakpoint-down(sm) {
						order: 2;
					}
				}
			}
		}

		h3 {
			color: $blue;
		}

		// image-collection
		.image-collection {
			margin-top: calc(-6vh - 45px);
			min-height: auto;
			height: 50vh !important;
			max-height: 440px;

			.item {
				min-height: auto;
				height: 50vh !important;
				max-height: 440px;
				padding: 0;
				align-items: flex-end;
				border-radius: 3px;
				overflow: hidden;

				.owl-container {
					background: linear-gradient(180deg, rgba($black, 0) 0%, rgba($black, 0.5) 100%);

					.owl-caption {
						padding: 15px;
						text-align: right;
						text-shadow: none;

						.owl-title {
							margin-bottom: 0;
							font-size: 14px;
							font-family: $font-family-primary;
						}
					}
				}
			}

			.owl-nav {
				display: none;
			}

			.owl-dots {
				left: 15px;
				right: auto;

				.owl-dot {
					span {
						width: 13px;
						height: 13px;
						border-width: 1px;
						box-shadow: none;
					}
				}
			}
		}

	}

	// icon-slider
	.icon-slider {
		margin-top: 6vh;

		.card {
			background: none;
			border: none;

			.card-image {
				width: 80px;
				height: 80px;
				margin: auto;
				background: $blue;
				border: 2px solid $white;
				border-radius: 100%;
				box-shadow: 6px 6px 6px rgba($black, 0.15);
				color: $white;
				font-size: 36px;
				line-height: 76px;
				transition: 0.5s;
			}

			&[href]:hover {
				.card-image {
					background: $white;
					border-color: $blue;
					color: $blue;
				}
			}

			.card-body {
				margin-top: 15px;
				padding: 0;

				.card-caption {
					text-align: center;

					.card-title {
						margin-bottom: 0;
						color: $blue;
						font-size: 18px;
					}

					.card-subtitle {
						margin: 0;
						font-size: 14px;
					}
				}
			}
		}
	}

}

// highlight-section
// =========================================================================
.highlight-section {
	position: relative;
	z-index: 2; // above 'M'
	padding: 6vh 0;
	background: url('/images/wood-pattern-bg.svg') $gray-dark;
	background-size: 431px 435px;

	// collection
	.collection {
		.card {
			background: none;
			border: none;

			.card-image {
				display: none;
			}

			.card-body {
				padding: 0;

				.card-caption {
					color: $white;

					.card-title {
						@include font-size($h2-font-size);
						line-height: 1;
					}

					.card-subtitle,
					.card-description {
						max-width: 720px;
						font-size: 18px;
						font-weight: 700
					}
				}

				.card-buttons {
					.card-btn {
						background-color: $gray-dark;
						border-color: $white;
						color: $white;

						&:hover {
							background-color: $blue;
							border-color: $blue;
							color: $white;
						}
					}
				}
			}
		}

		.owl-nav {
			@include media-breakpoint-down(lg) {
				margin: 15px 0 -15px 0;
				display: flex;
				justify-content: center;
			}

			.owl-prev {
				left: -45px;
			}

			.owl-next {
				right: -45px;
			}

			.owl-prev,
			.owl-next {
				color: $white;

				@include media-breakpoint-down(lg) {
					position: static;
					transform: none;
				}
			}
		}
	}
}

// lead-section
// =========================================================================
.lead-section {
	margin: 6vh 0;

	.container-one-column {
		.container-holder {
			max-width: 750px;
			margin: 0 auto;
			text-align: center;
		}
	}

	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: 30px;
	}

	.info {

		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .justify-content-center;
			@extend .list-unstyled;

			li {
				margin: 0 15px;
			}

			.icon {
				margin-right: 5px;
			}
		}

		// assortiment
		.former-price {
			font-size: $font-size-sm;
			text-decoration: line-through;
		}

		.discount-price {
			font-size: $font-size-sm;

			.amount {
				font-size: $font-size-lg;
			}

			margin-bottom: 1rem;
		}

		.price {
			margin-bottom: 1rem;

			.amount {
				font-size: $font-size-lg;
			}
		}

	}
}

// content-section
// =========================================================================
.content-section {
	margin: 6vh 0;
}

// banner-section
// =========================================================================
.banner-section {
	position: relative;
	z-index: 2; // above 'M'

	// owl-carousel
	.owl-carousel {
		height: 75vh;
		min-height: 480px;
		max-height: 720px;

		.item {
			align-items: flex-end;
			height: 75vh !important; // overide
			min-height: 480px;
			max-height: 720px;
			padding: unset; // overide

			.owl-container {
				position: relative;
				margin-bottom: 50px;

				.owl-caption {
					text-shadow: none;
					text-align: left;

					.owl-title {
						@include font-size($h1-font-size);
					}
				}
			}
		}

		.owl-dots {
			display: none;
		}
	}

}

// tiles-collection-section
// =========================================================================
.tiles-collection-section {
	padding: 6vh 0;
	background: url('/images/wood-pattern-bg.svg') $blue-light;
	background-size: 431px 435px;

	// wysiwyg
	.wysiwyg {
		max-width: 720px;
		margin: 0 auto 30px auto;
	}

	// collection
	.collection {
		&.grid {
			.grid-items {
				.item {
					display: flex;
					@include make-col(12);

					@include media-breakpoint-up(md) {
						@include make-col(6);
					}

					.card {
						border: none;
						border-radius: 3px;

						.card-img-overlay {
							display: flex;
							flex-wrap: wrap;
							justify-content: flex-end;
							border-radius: 3px;
							padding: 0;
							background: none;

							.card-caption {
								margin-top: auto;
								padding: 15px;

								@include media-breakpoint-up(xl) {
									padding: 20px;
								}

								background: linear-gradient(180deg, rgba($black, 0) 0%, rgba($black, 0.7) 100%);
								text-shadow: none;

								.card-title {
									margin-bottom: 0;
									@include font-size($h4-font-size);
								}

								.card-subtitle {
									display: none;
								}
							}
						}
					}
				}
			}
		}
	}
}

// outro-section
// =========================================================================
.outro-section {
	margin: 6vh 0;

	h4 {
		@include media-breakpoint-up(xl) {
			font-size: 30px;
		}
	}

	// faq-overview
	.faq-overview {
		.faq-category {
			.faq-items {
				margin: 0;
				border-top: 1px solid $black;

				.faq-item {
					margin-bottom: 0;
					padding: 10px 0;
					border-bottom-color: $black;

					.faq-question {
						h3 {
							color: $blue;
							font-size: 14px;
						}

						.faq-icon {
							color: $blue;
						}
					}

					.faq-answer {
						margin: 5px 15px 0 0;
					}
				}
			}
		}
	}
}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	margin: 6vh 0;

	// collection
	.collection {
		.card {
			@extend .default-card;
		}
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	margin: 6vh 0;
}

// gallery-section
// =========================================================================
.gallery-section {
	margin: 6vh 0;
	padding: 6vh 0;
	background: url('/images/wood-pattern-bg.svg') $blue-light;
	background-size: 431px 435px;
}

// booking-section
// =========================================================================
.booking-section {
	margin: 6vh 0;

	h2 {
		margin-bottom: 30px;
	}
}
