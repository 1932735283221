// header
.header {
	z-index: 998;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	transition: 0.5s;
	padding: 15px 0;

	&.sticky {
		padding: 5px 0;
		background: $white;
		box-shadow: 0 6px 12px rgba($black, 0.15);
	}

	.container-fluid {
		.container-holder {
			>.column {
				@extend .align-items-center;

				@include media-breakpoint-down(lg) {
					flex-flow: row wrap;
				}

				@include media-breakpoint-up(xl) {
					padding: 0 45px;
				}

				@extend .navbar-expand-xl;
			}
		}
	}

	// menu-toggle
	.menu-toggle {
		@include media-breakpoint-down(xs) {
			margin-right: auto;
		}

		.navbar-toggler {
			width: 45px;
			height: 45px;
			margin-right: 15px;
			padding: 0;
			border: 1px solid rgba($blue, 0.15);
			background: $white;
			border-radius: 100%;
			color: $blue;
			font-size: 24px;
			font-weight: 700;
			font-family: $font-family-secondary;
			text-transform: uppercase;
			transition: color 0.5s;

			i {
				line-height: 45px;
			}

			&[aria-expanded="true"] {
				i {
					&::before {
						content: "\f00d";
					}
				}
			}
		}
	}

	// logo
	.logo {
		@include media-breakpoint-down(xs) {
			order: 4;
			display: flex;
			width: 100%;
		}

		width: 236px;
		margin-right: auto;
		transition: width 0.5s;

		a {
			@extend .d-block;

			img {
				@extend .w-100;
			}
		}
	}

	&.sticky {
		.logo {
			width: 160px;
		}
	}

	// menu
	.menu {
		@include media-breakpoint-down(lg) {
			order: 5;

			.navbar-nav {
				margin: 30px 0 15px 0;

				>.nav-item {
					>.nav-link {
						display: flex;
						align-items: center;
						color: $black;
						font-size: 15px;
						font-weight: 700;

						&:hover {
							color: $blue;
						}

						&.dropdown-toggle {
							&::after {
								content: "\f107";
								font-size: 20px;
								font-family: $font-awesome;
								border: none;
							}
						}
					}

					&.active {
						>.nav-link {
							color: $blue;
						}
					}
				}

				.nav-item {
					.dropdown-menu {
						position: static !important;
						transform: none !important;
						margin: 0;
						padding: 0;
						border: none;
						box-shadow: none;
						background: transparent;

						.nav-link {
							color: $black;
							font-size: 14px;

							&:hover {
								color: $blue;
							}

							&:first-of-type {
								.nav-link {
									padding-top: 0;
								}
							}
						}

						&.active {
							.nav-link {
								color: $blue;
							}
						}
					}
				}
			}
		}

		@include media-breakpoint-up(xl) {
			.navbar-nav {
				margin-left: auto;

				>.nav-item {
					margin-right: 20px;

					>.nav-link {
						padding-left: 0 !important;
						padding-right: 0 !important;
						color: $black;
						font-size: 14px;
						font-weight: 700;

						&:hover {
							color: $blue;
						}
					}

					&.active {
						>.nav-link {
							color: $blue;
						}
					}
				}

				.nav-item {
					.dropdown-menu {
						padding: 1rem;
						border-radius: 3px;
						border: none;
						left: -30px !important;
						transform: unset !important;

						&::before,
						&::after {
							left: 30px;
							right: auto;
							border-bottom-width: 12px;
						}

						.nav-item {
							font-size: 14px;
							font-weight: 400;
							line-height: 1.2;
							white-space: nowrap;

							.nav-link {
								color: $black;

								&:hover {
									color: $blue;
								}
							}

							&.active {
								.nav-link {
									color: $blue;
								}
							}
						}
					}
				}
			}
		}
	}

	// book-button
	div.book-button {
		ul {
			li {
				a {
					@extend .btn;
					@extend .btn-primary;
					font-family: $font-family-primary !important;
				}
			}
		}
	}

	// site-switcher
	.site-switcher {
		margin-left: 15px;

		@include media-breakpoint-down(lg) {
			margin-left: 10px;
		}

		.navbar-nav {
			&.language {
				.nav-item {
					&.active {
						.nav-link {
							box-shadow: 0 0 0 1px rgba($blue, 1);
						}
					}
				}
			}
		}
	}
}

// body
&.navbar-active {
	.header {
		background: $white;
	}
}
