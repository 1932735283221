// btn
.btn {
	@include media-breakpoint-up(lg) {
		padding-left: 2rem;
		padding-right: 2rem;
	}
	border-radius: 25px;
	font-size: 14px;
	font-weight: 700;
	font-family: $font-family-secondary;

	// btn-primary
	&.btn-primary {
		&:hover {
			color: $white;
			background-color: $gray-dark;
			border-color: $gray-dark;
		}
	}

	// btn-outline-primary
	&.btn-outline-primary {
		&:hover {}
	}

	// btn-secondary
	&.btn-secondary {
		&:hover {
			color: $white;
			background-color: $blue;
			border-color: $blue;
		}
	}

	// btn-outline-secondary
	&.btn-outline-secondary {
		&:hover {}
	}
}

// card-btn
.card-btn {
	@extend .btn;
	@extend .btn-primary;
}

// btn-back
.btn-back {
	position: relative;
	display: inline-flex;
	align-items: center;
	padding-left: 1rem;

	&::before {
		content: "\f0d9";
		position: absolute;
		left: 0;
		font-weight: 900;
		font-family: $font-awesome;
	}
}

// btn-down
.btn-down {
	@extend .btn;
	@extend .btn-primary;
}
