// body
& {
	font-size: 14px;

	a[id]:not([href]) {
		top: $link-anchor-offset;
		position: absolute;
	}

	&.home {
		&::after {
			content: "";
			z-index: 1;
			position: fixed;
			right: 15px;
			bottom: 15px;
			width: 265px;
			height: 315px;
			background: url('/images/logo-visual.svg');
			background-size: cover;

			@include media-breakpoint-up(xl) {
				width: 402px;
				height: 477px;
			}
		}
	}
}

// headings
h1,
h2 {
	line-height: 1;
}

// img & iframe
img {
	max-width: 100%;
	height: auto;
}

iframe {
	max-width: 100%;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.5s;
}

// form
.form {
	padding: 15px;

	@include media-breakpoint-up(xl) {
		padding: 30px;
	}

	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

// default-card
.default-card {
	border: none;
	border-radius: 0;
	overflow: visible;

	.card-image {
		border: 2px solid $white;
		box-shadow: 0 0 6px rgba($black, 0.15);
	}

	.card-body {
		margin-top: 15px;
		padding: 0;

		.card-caption {
			.card-title {
				color: $blue;
				@include font-size($h4-font-size);
			}

			.card-subtitle {
				@include font-size($h5-font-size);
			}
		}
	}
}

// owl-nav
.owl-carousel {
	.owl-nav {

		.owl-prev,
		.owl-next {
			font-size: 40px;

			@include media-breakpoint-up(xl) {
				font-size: 60px;
			}

			text-shadow: none;

			i {
				font-weight: 300;
			}
		}
	}
}

// info-nav
&.accommodation-category-detail {
	.info-nav {
		display: none !important;
	}
}
