// footer
.footer {
	@extend .bg-dark;
	position: relative;
	z-index: 2; // above 'M'
	margin-top: 8vh;
	padding-top: 6vh;
	background: url('/images/wood-pattern-bg.svg') $gray-dark;
	background-size: 431px 435px;
	text-align: center;

	// footer-outro
	.footer-outro {
		.container {
			max-width: 540px;
		}
	}

	// footer-link
	.footer-link {
		margin-top: 15px;

		.list {
			justify-content: center;
			.list-item {
				margin: 15px 15px 0 0;

				.link {
					@extend .btn;
					@extend .btn-primary;
					padding: 0.25rem 1rem !important;
					text-decoration: none !important;

					&::before {
						font-family: $font-awesome;
						display: block;
						float: left;
						margin-right: 10px;
						font-size: 16px;
						font-weight: 300;
						line-height: 1;
					}

					&[href^="tel:"] {
						&::before {
							content: "\f095";
						}
					}

					&[href^="mailto:"] {
						&::before {
							content: "\f0e0";
						}
					}
				}
			}
		}
	}

	// footer-logolink
	.footer-logolink {
		margin: 30px 0 6vh 0;

		.list {
			justify-content: center;
			.list-item {
				margin: 0 15px;
				.link {
					display: block;
					font-size: 36px;
					&:hover {
						color: $blue !important;
					}
				}

			}
		}
	}

	// back-to-top
	div.back-to-top {
		margin-top: 6vh;
		a {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			width: 50px;
			height: 30px;
			margin: 0 auto;
			background: url('/images/arrow-up.svg');
			&:hover {
				transform: translateY(3px);
			}
		}
	}
}
