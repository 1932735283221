// mini-sab
.mini-sab {
	.container {
		position: relative;
		@extend .d-flex;
		@extend .justify-content-center;
		max-width: 960px;

		.container-holder {
			z-index: 100;
			position: relative;
			margin: 0;
			width: 100%;

			@include media-breakpoint-down(md) {
				margin-top: -100px;
			}

			@include media-breakpoint-up(lg) {
				position: absolute;
				left: auto;
				right: auto;
				bottom: 50px;
			}

			>.column {
				padding: 0;
			}
		}
	}

	// plan-your-visit
	.plan-your-visit {
		position: relative;

		.nav-tabs {
			border-bottom: none;

			.nav-item {
				margin-right: 2px;
				font-size: 14px;
				font-weight: 700;
				text-align: center;

				.nav-link {
					margin: 3px 0 0 0;
					padding: 0 15px;

					@include media-breakpoint-up(md) {
						padding: 0 30px;
					}

					background-color: $white;
					border: 0;
					border-radius: 3px 3px 0 0;
					color: $gray;
					line-height: 33px;

					&:hover {
						background-color: $blue-light;
					}

					&.active {
						margin-top: 0;
						background-color: $blue;
						color: $white;
						line-height: 36px;
					}
				}
			}
		}

		.tab-content {
			.tab-pane {
				min-height: 96px;
				padding: 15px;

				@include media-breakpoint-up(md) {
					padding: 15px 30px;
				}

				background: $blue;
				border-radius: 0 3px 0 0;
				color: $white;
				font-size: 12px;

				.tab-pane-stay {
					display: flex;
					align-items: center;
					flex-direction: column;

					@include media-breakpoint-up(sm) {
						flex-direction: row;
					}

					.text {
						flex: 1;

						p {
							margin-bottom: 0;
						}

						strong {
							font-size: 13px;
						}
					}

					.buttons {
						display: flex;
						justify-content: flex-end;
						flex: 1;
						margin-top: 15px;

						.btn {
							border-color: $white;
							background-color: transparent;
							color: $white;
							font-size: 12px;
							line-height: 22px;

							&:hover {
								border-color: $gray-dark;
								background-color: $gray-dark;
								color: $white;
							}

							+.btn {
								margin-left: 15px;
							}
						}
					}
				}
			}
		}
	}

	.message {
		padding: 7px 15px;
		border-radius: 0 0 3px 3px;
		background: $gray-dark;
		color: $white;
		text-align: center;

		p {
			margin-bottom: 0;
		}

		a {
			color: $white;
			text-decoration: underline;

			&:hover {
				text-decoration: none;
			}
		}
	}
}
