// eyecatcher
.eyecatcher,
.eyecatcher .owl-carousel .item {
	height: 50vh;
	min-height: 300px;
	max-height: 540px;
}

.eyecatcher.large,
.eyecatcher.large .owl-carousel .item {
	height: 85vh;
	min-height: 540px;
	max-height: 1080px;

	@include media-breakpoint-up(sm) {
		height: 100vh;
	}
}

// eyecatcher
.eyecatcher {
	position: relative;

	.owl-carousel {
		.item {
			align-items: flex-end;

			&::before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				height: 50%;
				max-height: 350px;
				background: linear-gradient(180deg, rgba($white, 1) 0%, rgba($white, 0) 100%);
			}

			&::after {
				content: "";
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				height: 50%;
				max-height: 350px;
				background: linear-gradient(180deg, rgba($gray-dark, 0) 0%, rgba($gray-dark, 0.6) 100%);
			}
			.owl-caption-holder {
				z-index: 1;

				.owl-container {
					max-width: 960px;

					.owl-caption {
						text-align: left;

						@include media-breakpoint-down(md) {
							margin-bottom: 115px;
						}

						@include media-breakpoint-up(lg) {
							margin-bottom: 230px;
						}

						.owl-title {
							margin-bottom: 0;
							line-height: 1;
							@include font-size($h1-font-size);

							@include media-breakpoint-up(xl) {
								font-size: 70px;
							}
						}
					}
				}
			}
		}
	}

	// owl
	.owl-nav,
	.owl-dots {
		display: none;
	}

	// jump
	div.jump {
		a {
			z-index: 100;
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			width: 50px;
			height: 30px;
			margin: 0 auto;
			background: url('/images/arrow-down.svg');

			&:hover {
				bottom: -3px;
			}
		}
	}
}
